<template>

	<!-- VPN Tests Table Card -->
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
		<template #title>
			<a-row type="flex" align="middle">
				<a-col :span="24" :md="12">
					<h5 class="font-semibold m-0">VPN Servers Tests</h5>
				</a-col>
				
                <!--<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
				<a-input-search v-model="search_string" class="header-search" :class="searchLoading ? 'loading' : ''" placeholder="server search…" @change="toogleSearching" :loading='searchLoading'>
						
			     </a-input-search>
                </a-col>  -->			    

			</a-row>
		</template>
		<a-table :columns="columns" :data-source="data" :pagination="true">

			<template slot="server" slot-scope="server">
				<div class="table-avatar-info">
					<div class="avatar-info">
						<h6>{{ server.hostname }}</h6>						
					</div>
				</div>
			</template>

			<template slot="location" slot-scope="location">
				<div class="table-avatar-info">
				   <div class="avatar-info">
					  <h6>{{ location.country }}</h6>
					  <country-flag country='{{ location.country_code }}'  size='small'/>
					  <p>{{ location.city }}</p>
				   </div>	  
				</div>
			</template>

			<template slot="protocol" slot-scope="protocol">
				<div class="table-avatar-info">
				   <div class="avatar-info">
					  <h6>{{ protocol.name }}</h6>
				   </div>	  
				</div>
			</template>

			<template slot="connectivity" slot-scope="connectivity">
				<a-tag class="tag-status" :class="connectivity.connection ? 'ant-tag-success' : 'ant-tag-danger'">
					{{ connectivity.connection ? "OK" : "NOT" }}
				</a-tag>
			</template>

			<template slot="internet" slot-scope="internet">
				<a-tag class="tag-status" :class="internet.status ? 'ant-tag-success' : 'ant-tag-danger'">
					{{ internet.status ? "OK" : "NOT" }}
				</a-tag>
			</template>

			<template slot="updatedAt" slot-scope="updatedAt">
				<div class="table-avatar-info">
				   <div class="avatar-info">
					  <h6>{{ updatedAt }}</h6>					  
				   </div>	  
				</div>
			</template>


		</a-table>
	</a-card>
	<!-- / VPN Tests Table Card -->

</template>

<script>
    import CountryFlag from 'vue-country-flag'

	export default ({
		props: {
			data: {
				type: Array,
				default: () => [],
			},
			columns: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			return {
				// Active button for the "VPN Test" table's card header radio button group.
				authorsHeaderBtns: 'all',
			}
		},
		methods: {
	        update(value)
	        {
	            console.log(value.target.value)
	            this.$emit("updateMode",value)
	        },            
        },
	})

</script>
