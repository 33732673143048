<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div>

		<!-- VPN test Table -->
		<a-row :gutter="24" type="flex">
            
			<!-- VPN Test Table Column -->
			<a-col :span="24" class="mb-24">

				<!-- VPN Test Table Card -->
				<CardVPNTestTable
					:data="table1Data"
					:columns="table1Columns"
					@updateMode="update($event)"									
				></CardVPNTestTable>
				<!-- / VPN Test Table Card -->

			</a-col>
			<!-- / VPN Test Table Column -->

		</a-row>
		<!-- / VPN Test Table -->
		
	</div>
</template>

<script>

	// "VPN Test" table component.
	import CardVPNTestTable from '../components/Cards/CardVPNTestTable' ;
	const axios = require('axios');

	
	// "VPN Test" table list of columns and their properties.
	const table1Columns = [
		{
			title: 'HOSTNAME',
			dataIndex: 'server',
			scopedSlots: { customRender: 'server' },
		},
		{
			title: 'COUNTRY',
			dataIndex: 'location',
			scopedSlots: { customRender: 'location' },
		},
		{
			title: 'PROTOCOL',
			dataIndex: 'protocol',
			scopedSlots: { customRender: 'protocol' },
		},
		{
			title: 'CONNECT',
			dataIndex: 'connectivity',
			scopedSlots: { customRender: 'connectivity' },
		},
		{
			title: 'INTERNET',
			dataIndex: 'internet',
			scopedSlots: { customRender: 'internet' },
		},
		{
			title: 'LAST_UPDATE',
			dataIndex: 'updatedAt',
			scopedSlots: { customRender: 'updatedAt' },
		},		
	];

	// "VPN Test" table list of rows and their properties.
	const table1Data = [];


	export default ({
		components: {
			CardVPNTestTable
		},
		data() {
			return {
				// Associating "VPN Test" table data with its corresponding property.
				table1Data: table1Data,

				// Associating "VPN Test" table columns with its corresponding property.
				table1Columns: table1Columns,

			}
		},
		mounted(){
         this.getServersStatus('all');
		},
		methods:{
		getServersStatus(mode){
		    let url = ""		    
          url = "https://theserverdashboard.info:7200/api/vpnservertest/v1/list"
          
			axios.get(url)
	      .then((response) => {
	        this.table1Data = []
	        this.servers = response.data.data 
	        this.total =  response.data.count
	        
            let serv = {}
	        for(let i in this.servers){
		          serv =  {
					key: i,					
					server: {
					  ipaddress:this.servers[i].ipaddress,
					  hostname:this.servers[i].hostname,
					},
					location: {
                      country: this.servers[i].country,
                      city: this.servers[i].city,
                      country_code: this.servers[i].country_code.toLowerCase(),
					},
					connectivity: {
					  connection: this.servers[i].connection,
					},
					internet:{  
					  status: this.servers[i].internet,	
					},
					protocol: {
                    name: this.servers[i].protocol,
					},									
					updatedAt: this.servers[i].updatedAt
			     }	  

			     this.table1Data.push(serv)       
	        }	        


	        console.log(this.table1Data)
	      })

		},
		update(event){
		   console.log(event.target.value)
		   this.getServersStatus(event.target.value)
		},
		toogleSearching( value ) {
				console.log("$$$$$$$$$")
				console.log(value.target.value) ;
		},
		},
	})

</script>

<style lang="scss">
</style>
